import React, { useState } from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import SellHome from "../component/Sell/Sell";
import { NewsletterForm } from "../component/ContactSlideout/NewsletterPopup";

function encode(data) {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }
  return formData;
}

const Sell = () => {
  const [state, setState] = useState({});

  const handleChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(
        () =>
          (document.getElementById("apply-form-ajax-response").innerHTML =
            "Thank you for signing up!"),
        form.remove(),
        (window.dataLayer = window.dataLayer || []),
        window.dataLayer.push({
          event: "newsletterFormSubmission",
        })
      )
      .catch((error) => alert(error));
  };

  return (
    <LayoutWrapper
      title="Newsletter | Real Estate | The Selby Team"
      desc="Subscribe to the Selby Team Newsletter."
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[100px] px-[80px] lg:px-[40px] relative h-[700px] justify-center flex items-center">
        <NewsletterForm handleChange={handleChange} handleSubmit={handleSubmit} />
      </div>
    </LayoutWrapper>
  );
};

export default Sell;
